<template>
    <div>
        <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
            <v-col class="text-start mt-0 mb-3">
                <!-- <v-alert text dense color="#8061c2" border="left" class="pl-5">
                    <h1 class="title mt-1">Publicidad</h1>
                    <p class="desc mt-0 mb-1">
                        Estadisticas de publicidad en los sitios.
                    </p>
                </v-alert> -->
                    <v-col class="col-12 px-0 pr-md-2 pt-0 mt-0">
                        <v-card cols="12" class="rounded-lg px-5  pb-5" elevation="2">
                            <div class="d-flex">
                                <h3 class="text-left title-dashboard">Totales</h3>
                                <v-progress-circular v-if="loadingStatistics" indeterminate color="#8061c2" width="2"
                                    size="20" class="mt-1 ml-2"></v-progress-circular>
                            </div>
                            <p class="text-left desc-dashboard mb-4">Conteo de impresiones, clicks y ganancias de los ultimos 60 dias.</p>
                            <v-row no-gutters>

                                <div class="col-12 col-md-4 px-md-4 px-md-5 px-0 my-2 my-md-0 pl-md-0">
                                    <div class="rounded-lg d-card2 text-left px-4 py-2">
                                        <v-icon size="25px" color="#40d756" class="mt-2">mdi-cursor-default-click</v-icon>
                                        <p class="mt-3 mb-0 title-card">{{ trends.clicks || 0 }}</p>
                                        <p class="title-desc mb-0">Total Clicks</p>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4 px-md-4 pl-md-0">
                                    <div class="rounded-lg d-card text-left px-4 py-2">
                                        <v-icon size="25px" color="#f9947b" class="mt-2">mdi-cash-sync</v-icon>
                                        <p class="mt-3 mb-0 title-card">{{ trends.conversions || 0  }}</p>
                                        <p class="title-desc mb-0">Total Conversiones</p>
                                    </div>
                                </div>


                                <div class="col-12 col-md-4 px-md-4 pl-md-0">
                                    <div class="rounded-lg d-card3 text-left px-4 py-2">
                                        <v-icon size="25px" color="#be82fe" class="mt-2">mdi-cash</v-icon>
                                        <p class="mt-3 mb-0 title-card">${{ trends.revenue || 0 }}</p>
                                        <p class="title-desc mb-0">Total Ganancias</p>
                                    </div>
                                </div>
                            </v-row>
                        </v-card>
                    </v-col>
            </v-col>

            <!-- Search Bar y Filtros -->
            <v-row class="mx-3">
                <v-col cols="12" md="4" class="px-0">
                    <v-text-field v-model="searchTable" color="#8061c2" label="Buscar"
                        append-icon="mdi-magnify" single-line hide-details outlined
                        class="mb-2 mx-0 px-0"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" class="px-md-2 px-0">
                    <v-menu ref="startDateMenu" v-model="startDateMenu" :close-on-content-click="false"
                        :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="startDate" label="Fecha Inicio" readonly v-bind="attrs" v-on="on"
                                outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate"  :min="minAllowedDate"
                            @input="startDateMenu = false"></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="12" md="2" class="pr-md-2 px-0">
                    <v-menu ref="endDateMenu" v-model="endDateMenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="endDate" label="Fecha Fin" readonly v-bind="attrs" v-on="on"
                                outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="endDate" :min="startDate" 
                            @input="endDateMenu = false"></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="12" md="2" class="pr-md-2 px-0">
                    <v-select v-model="sortField" :items="sortFieldOptions" label="Ordenar por" outlined hide-details
                        @change="fetchAdsStats"></v-select>
                </v-col>

                <v-col cols="12" md="2" class="pr-md-2 px-0">
                    <v-select v-model="sortOrder" :items="sortOrderOptions" label="Orden" outlined hide-details
                        @change="fetchAdsStats"></v-select>
                </v-col>
            </v-row>

            <!-- Tabla de datos -->
            <v-data-table :headers="headers" :items="conversions" :loading="loading" class="elevation-1 mx-3 px-2 py-4"
                :items-per-page="perPage" :server-items-length="totalConversions" :page.sync="page" :footer-props="{
                    itemsPerPageOptions: [50, 100, 200, { text: 'All', value: 0 }],
                    showFirstLastPage: true
                }" @update:page="fetchAdsStats" @update:items-per-page="changePerPage">
                <!-- Columnas y Templates -->

              <template v-slot:item.site.logo_path="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="40" class="clickable-avatar" 
                                 @click="item?.site?.logo_path && showLogo(item.site.logo_path)" 
                                 v-bind="attrs" 
                                 v-on="on">
                            <v-img v-if="item?.site?.logo_path" :src="item.site.logo_path"></v-img>
                            <v-avatar v-else color="black">
                                <span class="white--text">
                                    {{ item?.user?.first_name?.charAt(0) || '?' }}{{ item?.user?.last_name?.charAt(0) || '?' }}
                                </span>
                            </v-avatar>
                        </v-avatar>
                    </template>
                    <span>{{ item?.site?.logo_path ? 'Ver Imagen' : 'Sin imagen' }}</span>
                </v-tooltip>
            </template>



            <template v-slot:item.statistics.payout="{ item }">
                $ {{ item.statistics.payout }} USD
            </template>

                <!-- Botones de acciones -->
                <template v-slot:item.actions="{ item }">
                    <!-- Botón para impersonar usuario -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on"
                                @click="impersonateUser(item.user.id)">
                                <v-icon>mdi-account-switch</v-icon>
                            </v-btn>
                        </template>
                        <span>Impersonar Usuario</span>
                    </v-tooltip>

                    <!-- Nuevo botón para ir al Harmony -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="green" v-bind="attrs" v-on="on" @click="goToHarmony(item.site.slug)">
                                <v-icon>mdi-web</v-icon>
                            </v-btn>
                        </template>
                        <span>Ir al Harmony</span>
                    </v-tooltip>
                </template>
            </v-data-table>

            <!-- Modal para ampliar el logo -->
            <v-dialog v-model="dialog" max-width="14rem" class="rounded-lg">
                <v-img :src="selectedLogo" width="14rem"></v-img>
            </v-dialog>
        </v-col>
    </div>
</template>

<script scoped>
import axios from '@/axios.js';
import moment from 'moment';
import debounce from 'lodash/debounce';
import impersonationMixin from '@/mixins/impersonationMixin';


export default {
    name: 'Conversions',
    mixins: [impersonationMixin],
    data() {
        const today = moment().format('YYYY-MM-DD');
        //const threeMonthsAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');
        const maxAllowedDate = moment().format('YYYY-MM-DD');
        const minAllowedDate = moment('2025-01-01').format('YYYY-MM-DD');

        return {
            conversions: [],
            search: '',
            loading: true,
            dialog: false,
            selectedLogo: null,
            page: 1,
            perPage: 50,
            totalConversions: 0,
            trends: {
                clicks: 0,
                conversions: 0,
                revenue: 0
            },
            sortField: 'clicks',
            sortOrder: 'desc',
            sortFieldOptions: [{
                text: 'Clicks',
                value: 'clicks'
            },
            {
                text: 'Conversiones',
                value: 'conversions'
            },
            {
                text: 'Ganancias',
                value: 'payout'
            },
            ],
            sortOrderOptions: [{
                text: 'Mayor a menor',
                value: 'desc'
            },
            {
                text: 'Menor a mayor',
                value: 'asc'
            },
            ],
            startDate: '2025-01-01',
            endDate: today,
            startDateMenu: false,
            endDateMenu: false,
            maxAllowedDate: maxAllowedDate,
            minAllowedDate: minAllowedDate,
            headers: [{
                text: 'Avatar',
                value: 'site.logo_path',
                align: 'start',
            },
            {
                text: 'Harmony',
                value: 'site.name',
                align: 'start',
            },
            {
                text: 'Clicks',
                value: 'statistics.clicks',
                align: 'start',
            },
            {
                text: 'Conversiones',
                value: 'statistics.conversions',
                align: 'start',
            },
            {
                text: 'Ganancias',
                value: 'statistics.payout',
                align: 'start',
            },
            {
                text: 'Acciones',
                value: 'actions',
                sortable: false,
            },
            ],
            loadingStatistics: true,
        };
    },
    watch: {
        sortField() {
            if (this.page === 1) {
                this.fetchAdsStats();
            } else {
                this.page = 1; // El watcher de page llamará a fetchAdsStats
            }
        },
        sortOrder() {
            if (this.page === 1) {
                this.fetchAdsStats();
            } else {
                this.page = 1;
            }
        },
        startDate() {
            if (this.page === 1) {
                this.fetchAdsStats();
            } else {
                this.page = 1;
            }
        },
        endDate() {
            if (this.page === 1) {
                this.fetchAdsStats();
            } else {
                this.page = 1;
            }
        }
    },

    created() {
        this.fetchAdsStats();
        this.fetchGlobalStats();
    },

    computed: {
        searchTable: {
            get() { return this.search },
            set: debounce(function (value) {
                this.search = value
                this.fetchAdsStats();
            }, 400)
        }
    },
    methods: {
        showLogo(logoPath) {
            this.selectedLogo = logoPath ? logoPath : require('../../../assets/illustrations/no-photo.jpg');
            this.dialog = true;
        },

        async fetchAdsStats() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/get-statistics-ads', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        sort_field: this.sortField,
                        sort_order: this.sortOrder,
                        start_date: this.startDate,
                        end_date: this.endDate,
                    },
                });
                this.conversions = response.data.data;
                this.totalConversions = response.data.total;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },


        async fetchGlobalStats() {
            try {
                const response = await axios.get('/admin/get-global-statistics');
                this.trends = response.data.trends || {
                    conversions: 0,
                    clicks: 0,
                    revenue: 0
                };
            } catch (error) {
                console.error(error);
                this.trends = {
                    conversions: 0,
                    clicks: 0,
                    revenue: 0
                };
            } finally {
                this.loadingStatistics = false;
            }
        },

        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1;
            this.fetchAdsStats();
        },

        goToHarmony(slug) {
            const harmonyUrl = `${window.location.origin}/${slug}`;
            window.open(harmonyUrl, '_blank');
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },
    },
};
</script>

<style scoped>
.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}
</style>
<style>
.flags {
    width: 21px !important;
}

.chip-container {
    display: flex;
    flex-wrap: wrap;
    /* Permite que se dividan en múltiples filas */
    max-height: 100px;
    /* Ajusta esta altura si es necesario */
    overflow-x: auto;
    /* Scroll horizontal */
    background-color: #f4e8ff;
    border-radius: 12px;
}

.title-desc {
    font-size: 15px;
    color: #676f7b;
}


::v-deep .v-chip__content {
    font-weight: 800;
    color: #8e84c0;
    font-size: 10px;
    height: 18px !important;
}

::v-deep .v-chip {
    height: 15px !important;
}

.custom-tab {
    text-transform: none;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 16px;
}

.scroll-data {
    height: 260px;
    overflow-y: auto;
}

.round-tab {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.title-card {
    color: #616181;
    font-weight: 400;
    font-size: 26px;
}

.d-card {
    background-color: #fff4de;
}

.d-card2 {
    background-color: #dcfce7;
}

.d-card3 {
    background-color: #f4e8ff;
}

.title-dashboard {
    color: #8061c2;
    font-weight: 500;
    margin-left: 10px;
    margin-top: 8px;
}

.desc-dashboard {
    color: gray;
    font-size: 14px;
    margin-left: 10px;
}

.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}
</style>